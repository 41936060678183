import { Outlet, useParams } from "@remix-run/react";
import { skipToken, useQuery } from "@tanstack/react-query";
import * as React from "react";

import { Loading } from "~/components/loading";

import { Tenant } from "~/services/api-schemas";
import { requireUser } from "~/services/session";

import { invariant } from "~/utils/invariant";

import { fetchTenant } from "../$tenant.settings.client-and-devices/fetchers";

export async function clientLoader() {
  await requireUser();

  return null;
}

export default function Index() {
  const tenantId = useRouteTenantId();

  const { data: tenant, isPending } = useQuery({
    queryKey: ["tenant", tenantId],
    queryFn: tenantId ? () => fetchTenant(tenantId) : skipToken,
  });

  if (isPending) return <Loading />;

  return (
    <TenantContext.Provider value={tenant}>
      <Outlet />
    </TenantContext.Provider>
  );
}

const TenantContext = React.createContext<Tenant | undefined>(undefined);

export function useRouteTenantId(fallback: string | null = null) {
  const { tenant: tenantId } = useParams<"tenant">();
  return tenantId || fallback;
}

export function useTenant() {
  const tenant = React.useContext(TenantContext);
  invariant(tenant, "useTenant must be used within a TenantContext.Provider");
  return tenant;
}
